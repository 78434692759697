import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAieXuoczfScc6O_9QLTWSCZKjbU-tJ89o",
  authDomain: "deepshah-portfolio.firebaseapp.com",
  projectId: "deepshah-portfolio",
  storageBucket: "deepshah-portfolio.appspot.com",
  messagingSenderId: "1066503047112",
  appId: "1:1066503047112:web:df8d4d9791cda4a28aa2fd",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, auth };
