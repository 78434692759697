import React, { useEffect, useState } from "react";
import Design from "./Design";

export default function Designs({ designs }) {
  return (
    <>
      <div
        className={`grid grid-cols-1 min-[540px]:!grid-cols-2 min-[1150px]:!grid-cols-3  mx-auto max-w-[900px] animate-design-initial-render w-fit gap-x-20 gap-y-10`}
      >
        {designs.map((design, index) => (
          <>
            <Design design={design} key={index} />
          </>
        ))}
      </div>
    </>
  );
}
