import React, { useContext, useEffect, useRef, useState } from "react";
import HomeBgVector from "../assets/home-bg-vector.svg";
import { useLocation } from "react-router-dom";
import { AppContext } from "../contexts/app";
import { motion } from "framer-motion";

export default function Home() {
  const location = useLocation();
  const [currentHeadingColor, setcurrentHeadingColor] = useState("white");

  const { isinitialLoad } = useContext(AppContext);

  const handleColorChange = () => {
    currentHeadingColor == "white"
      ? setcurrentHeadingColor("custom-yellow-1")
      : setcurrentHeadingColor("white");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="min-h-[calc(100vh_-_56px)] md:min-h-[calc(100vh_-_110px)] duration-1000"
      style={
        {
          // background: "#111111",
          // backgroundImage: `url(${HomeBgVector})`,
          // backgroundImage: `url(${HomeBgVector}), linear-gradient(103.05deg, #111111 0.57%, #303030 100%)`,
          // backgroundRepeat: "no-repeat",
          // backgroundPosition: "0 50%",
        }
      }
    >
      {/* <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(103.05deg, #111111 0.57%, #303030 100%)",
          zIndex: -1,
        }}
      /> */}
      <div
        className={`relative top-[35vh] pl-[20px] md:pl-[4vw] w-fit opacity-0 ${
          isinitialLoad.current
            ? "animate-home-main"
            : "animate-home-main-no-delay"
        }`}
      >
        <p
          className={`cursor-pointer text-${currentHeadingColor} font-semibold text-5xl z-0 ${
            isinitialLoad.current ? "" : "opacity-0"
          }`}
          onClick={handleColorChange}
        >
          DEEP SHAH
        </p>
        <div className="relative">
          <motion.p
            // initial={{ top: "0vh", color: "white" }}
            // animate={{ top: "0vh", color: "white" }}
            // exit={{ top: "-30vh", color: "black" }}
            // transition={{ duration: 0.5 }}
            className="relative text-white font-medium text-lg min-[380px]:!text-lg min-[500px]:!text-2xl min-[650px]:!text-3xl pb-1"
          >
            MOTION GRAPHICS & LOGO DESIGNER
          </motion.p>
          <div
            className={`${
              isinitialLoad.current
                ? "animate-home-main-border"
                : "animate-home-main-border-no-delay"
            } bg-custom-yellow-1 rounded-full`}
          ></div>
        </div>
      </div>
    </motion.div>
  );
}
