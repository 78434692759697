import React from "react";
import ContactBgVector from "../assets/contact-bg-vector.svg";
import { Link } from "react-router-dom";
import InstaIcon from "../assets/instagram.svg";
import TwitterIcon from "../assets/twitter.svg";
import LinkedInIcon from "../assets/linkedin.svg";
import { motion } from "framer-motion";

export default function Contact() {
  const email = "hello@deephah.co.in";

  const handleOpenMail = () => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      const uri = `mailto:${email}`;
      window.location.href = uri;
    } else if (/Win/i.test(navigator.userAgent)) {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    } else {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    }
  };

  return (
    <motion.div
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      // transition={{ duration: 0.4 }}
      className="min-h-[calc(100vh_-_56px)] md:min-h-[calc(100vh_-_72px)] flex justify-center items-center z-10 relative"
      // style={{
      // background: `url(${ContactBgVector})`,
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "0 50%",
      // }}
    >
      <motion.div
        initial={{ opacity: 0, top: "20vh" }}
        animate={{ opacity: 1, top: "0vh" }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="text-black text-center mb-12 relative"
      >
        <div>
          <p className="font-bold mb-3">Want to contact or need support?</p>
          <p className="mb-3">Just drop an email at</p>
          <p
            className="py-2 mb-3 bg-gradient-to-r from-gray-900 via-gray-700 to-gray-900 text-white font-bold rounded-xl text-center hover:text-custom-yellow-1 duration-500"
            onClick={handleOpenMail}
            role="button"
          >
            hello@deepshah.co.in
          </p>
          <div className="flex justify-center items-center relative mt-5 gap-2">
            <div className="h-0.5 w-12 bg-black"></div>
            <p>or</p>
            <div className="h-0.5 w-12 bg-black"></div>
          </div>
          <p className="mt-5">Connect on Social Media</p>
          <div className="flex gap-3 justify-center mt-3">
            <Link to="https://www.instagram.com/peepshah/" target="_blank">
              <img
                src={InstaIcon}
                className="h-10 hover:grayscale duration-150"
              />
            </Link>
            <Link to="https://twitter.com/PeepShah" target="_blank">
              <img
                src={TwitterIcon}
                className="h-10 hover:grayscale duration-150"
              />
            </Link>
            <Link to="https://www.linkedin.com/in/peepshah/" target="_blank">
              <img
                src={LinkedInIcon}
                className="h-10 hover:grayscale duration-150"
              />
            </Link>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
