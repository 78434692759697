import React, { useRef, useState } from "react";

export const AppContext = React.createContext();

function AppProvider({ children }) {
  const isinitialLoad = useRef(true);

  const [animatePortDiv, setanimatePortDiv] = useState(true);

  const value = {
    isinitialLoad: isinitialLoad,
    animatePortDiv,
    setanimatePortDiv,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
export default AppProvider;
