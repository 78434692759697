import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/admin";

function RequireAuth({ children, redirectTo }) {
  const { checkAuth, loggedIn, setloggedIn } = useContext(AuthContext);

  useEffect(() => {
    checkAuth()
      .then((res) => {
        setloggedIn(res);
      })
      .catch((err) => {
        setloggedIn(false);
      });
  }, [checkAuth]);

  if (loggedIn == null) {
    return null;
  } else {
    return children;
  }
}

export default RequireAuth;
