import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/admin";
import { AppContext } from "../contexts/app";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const { loggedIn, setloggedIn, logout } = useContext(AuthContext);
  const { isinitialLoad, animatePortDiv, setanimatePortDiv } =
    useContext(AppContext);

  const [currentPath, setcurrentPath] = useState("/");
  const [prevPath, setprevPath] = useState(window.location.pathname);

  const [currentHeadingColor, setcurrentHeadingColor] = useState("white");

  const handleColorChange = () => {
    currentHeadingColor == "white"
      ? setcurrentHeadingColor("custom-yellow-1")
      : setcurrentHeadingColor("white");
  };

  const [showDropdown, setshowDropdown] = useState(null);

  const handleLogout = async () => {
    await logout();
    setloggedIn(false);
    window.location.reload();
  };

  useEffect(() => {
    setcurrentPath(location.pathname);
    if (!isinitialLoad.current) setprevPath(location.state?.from);
  }, [location]);

  return (
    <>
      <nav>
        <div className="flex flex-nowrap items-center justify-between mx-auto px-[20px] md:px-[4vw] py-2">
          <div
            className={`flex items-center w-32`}
            onClick={() => (currentPath != "/" ? navigate("/") : null)}
          >
            <span
              className={`${currentPath == "/" ? "z-10" : ""}
                cursor-pointer self-center text-xl font-semibold whitespace-nowrap relative ${
                  currentPath != "/"
                    ? prevPath != "/"
                      ? ""
                      : "animate-nav-logo-transition-1"
                    : `text-${currentHeadingColor} animate-nav-logo-transition-2  ${
                        isinitialLoad.current ? "hidden" : "relative"
                      }
                      }`
                }`}
              onClick={handleColorChange}
            >
              DEEP SHAH
            </span>
          </div>

          <div className="flex md:order-2">
            {loggedIn && currentPath == "/portfolio" ? (
              <button
                className="bg-green-400 px-4 py-1 font-bold rounded-md text-white hidden md:block"
                onClick={handleLogout}
              >
                LOGOUT
              </button>
            ) : (
              <div className="hidden md:block w-24"></div>
            )}
            <div
              className="items-center p-2 w-10 h-10 justify-center text-sm md:hidden"
              onClick={() => {
                setshowDropdown(!showDropdown);
                // isinitialLoad.current = false;
              }}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke={`${currentPath == "/" ? "white" : "black"}`}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </div>
          </div>
          <div
            className={`items-center justify-center hidden md:flex md:w-auto md:order-1 ${
              currentPath == "/contact" ? "mr-1" : ""
            }`}
          >
            <ul className="flex p-4 md:p-0 md:flex-row md:space-x-8">
              <li>
                <Link
                  onClick={() => setanimatePortDiv(true)}
                  state={{ from: window.location.pathname }}
                  to="/"
                  className={`font-bold duration-1000 ${
                    currentPath == "/contact"
                      ? "text-black"
                      : currentPath == "/"
                      ? "text-custom-yellow-1"
                      : "text-custom-gray-1"
                  }`}
                >
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  state={{ from: window.location.pathname }}
                  to="/portfolio"
                  className={`font-bold duration-1000 ${
                    currentPath == "/portfolio" || currentPath == "/contact"
                      ? "text-black"
                      : currentPath == "/"
                      ? "text-white"
                      : "text-custom-gray-1"
                  }`}
                >
                  PORTFOLIO
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => setanimatePortDiv(false)}
                  state={{ from: window.location.pathname }}
                  to="/contact"
                  className={`font-bold duration-1000 ${
                    currentPath == "/contact" || currentPath == "/"
                      ? "text-white"
                      : "text-custom-gray-1"
                  }`}
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`overflow-hidden md:hidden bg-transparent ${
            showDropdown == true
              ? "animate-nav-dropdown-show"
              : showDropdown == false
              ? "animate-nav-dropdown-hide"
              : "h-0"
          } ${currentPath == "/" ? "absolute" : "relative"}`}
        >
          <ul className="flex flex-col p-4 md:p-0 md:flex-row md:space-x-8">
            <li>
              <Link
                state={{ from: window.location.pathname }}
                onClick={() => setshowDropdown(false)}
                to="/"
                className={`font-bold duration-1000 ${
                  currentPath == "/contact"
                    ? "text-black"
                    : currentPath == "/"
                    ? "text-custom-yellow-1"
                    : "text-custom-gray-1"
                }`}
              >
                HOME
              </Link>
            </li>
            <li className="mt-2">
              <Link
                state={{ from: window.location.pathname }}
                onClick={() => setshowDropdown(false)}
                to="/portfolio"
                className={`font-bold duration-1000 ${
                  currentPath == "/portfolio" || currentPath == "/contact"
                    ? "text-black"
                    : currentPath == "/"
                    ? "text-white"
                    : "text-custom-gray-1"
                }`}
              >
                PORTFOLIO
              </Link>
            </li>
            <li className="mt-2">
              <Link
                state={{ from: window.location.pathname }}
                onClick={() => setshowDropdown(false)}
                to="/contact"
                className={`font-bold duration-1000 ${
                  currentPath == "/contact" || currentPath == "/"
                    ? "text-white"
                    : "text-custom-gray-1"
                }`}
              >
                CONTACT
              </Link>
            </li>
            <li className="mt-2">
              {loggedIn && currentPath == "/portfolio" ? (
                <button
                  className="bg-green-400 px-4 py-1 font-bold rounded-md text-white w-full mt-2"
                  onClick={handleLogout}
                >
                  LOGOUT
                </button>
              ) : (
                ""
              )}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
