import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/admin";
import { useNavigate } from "react-router-dom";
import handlEnterKeyPress from "../utils/handleEnterKeyPress";

export default function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [apiCalled, setapiCalled] = useState(false);
  const [message, setmessage] = useState("");

  const navigate = useNavigate();

  const { login, setloggedIn } = useContext(AuthContext);

  const handleLogin = () => {
    if (email && password) {
      setapiCalled(true);
      login({ email, password })
        .then((res) => {
          setapiCalled(false);
          setloggedIn(true);
          navigate("/portfolio");
        })
        .catch((err) => {
          setmessage("INVALID CREDENTIALS");
          setTimeout(() => {
            setmessage("");
          }, 2000);
          setapiCalled(false);
        });
    }
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md sm:max-w-xl">
        <h1 className="text-3xl font-semibold text-center">LOGIN</h1>

        <div className="mb-2">
          <label
            for="email"
            className="block text-sm font-semibold text-gray-800"
          >
            Email
          </label>
          <input
            type="email"
            className="block w-full px-4 py-2 mt-2 border rounded-md outline-0"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="mb-2">
          <label
            for="password"
            className="block text-sm font-semibold text-gray-800"
          >
            Password
          </label>
          <input
            type="password"
            className="block w-full px-4 py-2 mt-2 border rounded-md outline-0"
            value={password}
            onChange={(e) => setpassword(e.target.value)}
            onKeyDown={(e) => handlEnterKeyPress(e, handleLogin)}
          />
        </div>
        <div className="mt-6">
          <button
            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-400 rounded-md"
            onClick={handleLogin}
            disabled={apiCalled}
          >
            {apiCalled ? "LOADING..." : "LOGIN"}
          </button>
        </div>
        {message && (
          <p className="mt-4 text-center font-bold text-sm text-red-400">
            {message}
          </p>
        )}
      </div>
    </div>
  );
}
