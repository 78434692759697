import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import PlayIcon from "../../assets/playIcon.svg";

export default function ViewModal({ showModal, setShowModal, design }) {
  const [videoPlaying, setvideoPlaying] = useState(true);
  const [loadingVideo, setloadingVideo] = useState(true);

  return (
    <div>
      <TEModal
        show={showModal}
        setShow={setShowModal}
        className="flex items-center"
      >
        <TEModalDialog
          className="min-[576px]:!max-w-[800px] min-[576px]:!w-[80vw] h-screen max-h-[calc(100vh_-_150px)] md:!h-auto w-full"
          centered
        >
          <TEModalContent className="overflow-scroll max-h-[calc(100vh_-_150px)] no-scrollbar">
            <TEModalHeader className="border-white !px-4 !pt-2 !pb-0">
              <div></div>
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody className="!pt-0 !mb-4 !mx-2">
              <div className="block relative">
                <div className="">
                  <input
                    readOnly
                    type="text"
                    value={design?.title}
                    className="font-bold text-md px-3 py-2 w-full border-0 outline-0 cursor-default"
                  />
                </div>
                <div className="w-full px-2">
                  <div class="mx-auto h-full w-full">
                    <div class="flex items-center justify-center w-full h-full relative">
                      <div
                        className="h-full w-full z-50 absolute flex justify-center items-center cursor-pointer"
                        onClick={() => setvideoPlaying(!videoPlaying)}
                      >
                        {!videoPlaying && (
                          <img src={PlayIcon} className="absolute h-12 w-12" />
                        )}
                      </div>

                      <div
                        class={`flex flex-col items-center justify-center w-full h-full rounded-lg bg-white duration-100 relative overflow-hidden`}
                      >
                        <ReactPlayer
                          playsinline
                          loop
                          onReady={() => setloadingVideo(false)}
                          playing={showModal && videoPlaying}
                          url={design?.mediaUrl}
                          width={"100%"}
                          height={"100%"}
                          className="w-auto h-full max-h-full max-w-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-fit mt-[30px] md:mt-0">
                  <div className=" h-full">
                    <textarea
                      readOnly
                      value={design?.description}
                      className="border-0 w-full px-3 py-2 mt-4 rounded outline-0 cursor-default h-fit resize-none"
                    />
                  </div>
                </div>
              </div>
            </TEModalBody>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
