import { addDoc, collection } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { db, storage } from "../../firebase";

export default function UploadModal({ showModal, setShowModal }) {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [video, setvideo] = useState(null);
  const [viedeoPreview, setviedeoPreview] = useState(null);
  const [videoPlaying, setvideoPlaying] = useState(true);
  const [apiCalled, setapiCalled] = useState(false);

  const handleUpload = async () => {
    if (title && video) {
      try {
        setapiCalled(true);
        const videoRef = ref(storage, `videos/${Date.now()}`);
        await uploadBytes(videoRef, video);
        const downloadimg = await getDownloadURL(videoRef);

        await addDoc(collection(db, "Videos"), {
          title,
          description,
          mediaUrl: downloadimg,
          createdAt: new Date(),
        });
        setapiCalled(false);
        window.location.reload();
      } catch (err) {
        setapiCalled(false);
      }
    }
  };

  useEffect(() => {
    if (video) {
      setviedeoPreview(URL.createObjectURL(video));
    }
  }, [video]);

  return (
    <div>
      <TEModal
        show={showModal}
        setShow={setShowModal}
        className="flex items-center"
      >
        <TEModalDialog
          className="min-[576px]:!max-w-[1000px] min-[576px]:!w-[80vw] h-screen max-h-[calc(100vh_-_150px)] md:!h-auto w-full"
          centered
        >
          <TEModalContent className="overflow-scroll h-full no-scrollbar">
            <TEModalHeader className="border-white !px-4 !pt-2 !pb-0">
              <div></div>
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody className="!pt-0 !mb-4 !mx-2">
              <div className="block md:flex">
                <div className="h-96 flex-1 flex items-center">
                  <div class="max-w-2xl mx-auto h-60 w-full">
                    <div class="flex items-center justify-center w-full h-full">
                      <label
                        for="video-file"
                        class={`flex flex-col items-center justify-center w-full h-full rounded-lg cursor-pointer ${
                          viedeoPreview ? "bg-white" : "bg-custom-gray-3"
                        } duration-100`}
                      >
                        {viedeoPreview ? (
                          <div className="bg-black relative overflow-hidden">
                            <ReactPlayer
                              playsinline
                              loop
                              playing={videoPlaying}
                              url={viedeoPreview}
                              width={"100%"}
                              height={"100%"}
                              className="w-auto h-auto max-h-full max-w-full"
                            />
                          </div>
                        ) : (
                          // <video
                          //   autoPlay
                          //   src={viedeoPreview}
                          //   className="w-auto h-auto max-h-full max-w-full"
                          // />
                          <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <p class=" text-custom-gray-4 text-9xl">+</p>
                            <p class="text-xs text-custom-gray-4">
                              (Upload 1920 x 1080 video)
                            </p>
                          </div>
                        )}
                        <input
                          accept="video/*"
                          id="video-file"
                          type="file"
                          class="hidden"
                          onChange={(e) => setvideo(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="h-96 flex-1 mt-[30px] md:mt-0">
                  <div className="px-[15px] lg:px-[30px] py-1 h-full">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => settitle(e.target.value)}
                      placeholder="ADD TITLE..."
                      className="border text-md px-3 py-2 rounded-md w-full placeholder:font-bold placeholder:text-gray-500 focus:outline-0"
                    />
                    <textarea
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                      placeholder="ADD DESCRIPTION..."
                      className="border w-full px-3 py-2 mt-4 rounded resize-none focus:outline-0 h-80"
                    />
                  </div>
                </div>
              </div>
            </TEModalBody>
            <TEModalFooter className="border-white flex justify-center">
              <div>
                <button
                  className="bg-green-400 text-white px-20 rounded py-2 font-bold"
                  onClick={handleUpload}
                  disabled={apiCalled}
                >
                  {apiCalled ? "POSTING..." : "POST"}
                </button>
              </div>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
