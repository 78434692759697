import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { db, storage } from "../../firebase";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import ConfirmModal from "./ConfirmModal";

export default function EditModal({ showModal, setShowModal, design }) {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [video, setvideo] = useState(null);
  const [videoPreview, setvideoPreview] = useState(null);
  const [videoPlaying, setvideoPlaying] = useState(true);
  const [updateApiCalled, setupdateApiCalled] = useState(false);
  const [deleteApiCalled, setdeleteApiCalled] = useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const handleEdit = async () => {
    if (title && description && (video || videoPreview)) {
      let downloadVideo;
      try {
        setupdateApiCalled(true);
        if (video) {
          try {
            await deleteObject(ref(storage, design.mediaUrl));
          } catch (err) {}

          const videoRef = ref(storage, `videos/${Date.now()}`);
          await uploadBytes(videoRef, video);
          downloadVideo = await getDownloadURL(videoRef);
        }
        const updatedDoc = {
          title,
          description,
          mediaUrl: video ? downloadVideo : videoPreview,
        };

        await updateDoc(doc(db, "Videos", design.id), updatedDoc);
        window.location.reload();
      } catch (err) {
        setupdateApiCalled(false);
      }
    }
  };

  const handleDeleteAnimation = async () => {
    setdeleteApiCalled(true);
    setshowConfirmModal(false);
    try {
      await deleteObject(ref(storage, design.mediaUrl));
    } catch (err) {
      setdeleteApiCalled(false);
    }

    await deleteDoc(doc(db, "Videos", design.id));

    window.location.reload();
  };

  useEffect(() => {
    if (video) {
      setvideoPreview(URL.createObjectURL(video));
    }
  }, [video]);

  useEffect(() => {
    settitle(design?.title);
    setdescription(design?.description);
    setvideoPreview(design?.mediaUrl);
  }, [design]);

  return (
    <div>
      <TEModal
        show={showModal}
        setShow={setShowModal}
        className="flex items-center"
      >
        <ConfirmModal
          showModal={showConfirmModal}
          setShowModal={setshowConfirmModal}
          onConfirm={handleDeleteAnimation}
          title={"Confirm Delete"}
          text={"Are you sure you want to delete this animation?"}
        />

        <TEModalDialog
          className="min-[576px]:!max-w-[1000px] min-[576px]:!w-[80vw] h-screen max-h-[calc(100vh_-_150px)] md:!h-auto w-full"
          centered
        >
          <TEModalContent className="overflow-scroll h-full no-scrollbar">
            <TEModalHeader className="border-white !px-4 !pt-2 !pb-0">
              <div></div>
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody className="!pt-0 !mb-4 !mx-2">
              <div className="block md:flex">
                <div className="h-96 flex-1 flex items-center">
                  <div class="max-w-2xl mx-auto h-60 w-full">
                    <div class="flex items-center justify-center w-full h-full">
                      <label
                        for={design.id}
                        class={`flex flex-col items-center justify-center w-full h-full rounded-lg cursor-pointer ${
                          videoPreview ? "bg-white" : "bg-custom-gray-3"
                        } duration-100`}
                      >
                        {videoPreview ? (
                          <div className="bg-black relative overflow-hidden">
                            <ReactPlayer
                              loop
                              playsinline
                              playing={showModal && videoPlaying}
                              url={videoPreview}
                              width={"100%"}
                              height={"100%"}
                              className="w-auto h-auto max-h-full max-w-full"
                            />
                          </div>
                        ) : (
                          <div class="flex flex-col items-center justify-center pt-5 pb-6">
                            <p class=" text-custom-gray-4 text-9xl">+</p>
                            <p class="text-xs text-custom-gray-4">
                              (Upload 1920 x 1080 video)
                            </p>
                          </div>
                        )}
                        <input
                          accept="video/*"
                          id={design.id}
                          type="file"
                          class="hidden"
                          onChange={(e) => setvideo(e.target.files[0])}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="h-96 flex-1 mt-[30px] md:mt-0">
                  <div className="px-[15px] lg:px-[30px] py-1 h-full">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => settitle(e.target.value)}
                      placeholder="ADD TITLE..."
                      className="border text-md px-3 py-2 rounded-md w-full placeholder:font-bold placeholder:text-gray-500 focus:outline-0"
                    />
                    <textarea
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                      placeholder="ADD DESCRIPTION..."
                      className="border w-full px-3 py-2 mt-4 rounded resize-none focus:outline-0 h-80"
                    />
                  </div>
                </div>
              </div>
            </TEModalBody>
            <TEModalFooter className="border-white flex justify-center">
              <div className="flex gap-5 md:gap-10 flex-wrap justify-center">
                <button
                  className="bg-red-400 text-white px-20 rounded py-2 font-bold"
                  onClick={() => setshowConfirmModal(true)}
                  disabled={deleteApiCalled}
                >
                  {deleteApiCalled ? "DELETING..." : "DELETE"}
                </button>
                <button
                  className="bg-green-400 text-white px-20 rounded py-2 font-bold"
                  onClick={handleEdit}
                  disabled={updateApiCalled}
                >
                  {updateApiCalled ? "UPDATING..." : "UPDATE"}
                </button>
              </div>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
