import React, { useContext, useEffect, useRef, useState } from "react";
import Designs from "../components/Portfolio/Designs";
import Animations from "../components/Portfolio/Animations";
import UploadDesignModal from "../components/Modals/UploadDesignModal";
import UploadAnimationModal from "../components/Modals/UploadAnimationModal";
import { AuthContext } from "../contexts/admin";
import MainBgVector from "../assets/main-bg-vector.svg";

import Image from "../assets/image.jpg";
import Design from "../components/Portfolio/Design";

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import { motion } from "framer-motion";
import { AppContext } from "../contexts/app";

export default function Portfolio() {
  const [showDesigns, setshowDesigns] = useState(true);
  const [showDesignModal, setshowDesignModal] = useState(false);
  const [showSliderAnim, setshowSliderAnim] = useState(false);
  const [showAnimationModal, setshowAnimationModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [designs, setdesigns] = useState([]);
  const [animations, setanimations] = useState([]);

  const getDesigns = async () => {
    setloading(true);
    const docs = await getDocs(
      query(collection(db, "Images"), orderBy("createdAt", "desc"))
    );

    setdesigns(
      docs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      })
    );
    setloading(false);
  };

  const getAnimations = async () => {
    setloading(true);
    const docs = await getDocs(
      query(collection(db, "Videos"), orderBy("createdAt", "desc"))
    );
    // setanimations([
    //   {
    //     title: "123",
    //     description: "2312",
    //     mediaUrl: require("../assets/6eab26c6-222f-4396-a2bd-906ec32315fc.mp4"),
    //   },
    //   {
    //     title: "123",
    //     description: "2312",
    //     mediaUrl: require("../assets/video.mp4"),
    //   },
    // ]);
    setanimations(
      docs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      })
    );
    setloading(false);
  };

  useEffect(() => {
    setloading(true);
    Promise.all(getDesigns(), getAnimations())
      .then((res) => {
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }, []);

  const { loggedIn } = useContext(AuthContext);

  const { animatePortDiv } = useContext(AppContext);

  const handleToggleDesigns = (value) => {
    setshowSliderAnim(true);
    value ? setshowDesigns(true) : setshowDesigns(false);
  };

  const handleModalShow = () => {
    showDesigns ? setshowDesignModal(true) : setshowAnimationModal(true);
  };

  const isFirstPageLoad = useRef(true);

  useEffect(() => {
    if (isFirstPageLoad.current) {
      isFirstPageLoad.current = false;
    }
  }, []);

  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="mt-5 min-h-[calc(100vh_-_110px)]"
      // style={{
      //   background: `url(${MainBgVector})`,
      //   backgroundRepeat: "no-repeat",
      //   backgroundPosition: "0 50%",
      // }}
    >
      <motion.div
        initial={{ opacity: 0, top: "20vh" }}
        animate={{ opacity: 1, top: "0vh" }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="relative"
      >
        <motion.div
          exit={{ top: animatePortDiv ? "27vh" : "" }}
          transition={{ duration: 0.5 }}
          className="w-full m-auto max-w-[380px] relative"
        >
          <div className="relative">
            <div
              className={`h-12 min-[350px]:!h-7 mt-[5px] min-[350px]:mt-[1px] ${
                showDesigns
                  ? `w-32 min-[350px]:!w-36 ${
                      showSliderAnim ? "animate-design-back-left" : "ml-2"
                    }`
                  : "animate-design-back-right w-40 !max-w-[47vw] min-[350px]:!w-44"
              } bg-custom-yellow-1 absolute duration-500 rounded-[8px]`}
            />
            <div className="relative flex gap-8 justify-between px-4 py-1">
              <button
                className={`${showDesigns ? "" : ""}  px-2 rounded`}
                onClick={() => handleToggleDesigns(true)}
              >
                <p
                  className={`whitespace-normal min-[350px]:whitespace-nowrap ${
                    showDesigns ? "animate-design-text-shadow" : ""
                  }`}
                >
                  LOGO DESIGNS
                </p>
              </button>
              <button
                className={`${!showDesigns ? "" : ""}  px-2 rounded`}
                onClick={() => handleToggleDesigns(false)}
              >
                <p
                  className={`whitespace-normal min-[350px]:whitespace-nowrap ${
                    !showDesigns ? "animate-design-text-shadow" : ""
                  }`}
                >
                  LOGO ANIMATIONS
                </p>
              </button>
            </div>
          </div>
          <div class="bg-gradient-to-r from-gray-900 via-custom-gray-4 to-gray-900 h-1 w-full"></div>

          {loggedIn && (
            <div className="m-auto flex justify-center mt-10">
              <div
                className="border-2 border-custom-gray-2 border-dotted w-fit p-2 rounded-md"
                onClick={handleModalShow}
              >
                <button className="px-24 bg-red-400 text-white font-bold rounded-md py-2 hover:bg-custom-red-2 duration-150">
                  UPLOAD
                </button>
              </div>
            </div>
          )}
        </motion.div>
        {loading ? (
          <div className="flex justify-center">
            <div
              class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span class="!absolute !-m-px !h-px !w-px !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-12">
            {showDesigns ? (
              <Designs designs={designs} />
            ) : (
              <Animations animations={animations} />
            )}

            <UploadDesignModal
              showModal={showDesignModal}
              setShowModal={setshowDesignModal}
            />
            <UploadAnimationModal
              showModal={showAnimationModal}
              setShowModal={setshowAnimationModal}
            />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
}
