import React, { useContext, useEffect, useRef, useState } from "react";
import ViewAnimationModal from "../Modals/ViewAnimationModal";
import EditAnimationModal from "../Modals/EditAnimationModal";
import ReplaceIcon from "../../assets/replace-icon.svg";
import { AuthContext } from "../../contexts/admin";
import ReactPlayer from "react-player";

export default function Animation({ design }) {
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [imgLoaded, setimgLoaded] = useState(false);
  const [showIcon, setshowIcon] = useState(false);

  const { loggedIn } = useContext(AuthContext);

  const handleImgClick = () => {
    loggedIn ? setshowEditModal(true) : setshowDetailsModal(true);
  };

  const videoRef = useRef(null);

  const video = document.querySelector(`.react-player-${design.key} > video`);

  useEffect(() => {
    if (video) {
      if (imgLoaded) {
        video.style.opacity = 1;
      } else {
        video.style.opacity = 0;
      }
      // setimgLoaded(true);
      video.style.objectFit = "cover";
    }
  }, [video, imgLoaded]);

  return (
    <div key={design.key}>
      <div
        className="w-fit relative"
        onMouseEnter={() => setshowIcon(true)}
        onMouseLeave={() => setshowIcon(false)}
      >
        {showIcon && loggedIn && (
          <div className="absolute right-1 bottom-2 z-50">
            <img src={ReplaceIcon} />
          </div>
        )}
        <div
          className={`${
            imgLoaded ? "bg-white" : "bg-custom-gray-3"
          } max-h-[50vw] max-w-[90vw] rounded-lg w-[350px] h-[250px] min-[540px]:w-[480px] min-[540px]:!h-[300px] min-[1150px]:w-[340px] min-[1150px]:!h-[202.5px] overflow-hidden`}
        >
          {/* <video
            ref={videoRef}
            playsInline
            src={design.mediaUrl}
            className={`object-cover rounded-md w-full h-full cursor-pointer hover:grayscale duration-200 relative`}
            onClick={handleImgClick}
            onPlay={() => setimgLoaded(true)}
          /> */}
          <ReactPlayer
            ref={videoRef}
            onReady={() => setimgLoaded(true)}
            playsInline
            url={design.mediaUrl}
            height={"100%"}
            width={"100%"}
            playing={false}
            className={`react-player-${design.key} rounded-md w-full h-full cursor-pointer hover:grayscale duration-200 relative`}
            onClick={handleImgClick}
          />
        </div>
      </div>
      {/* {showDetailsModal && ( */}
      <ViewAnimationModal
        showModal={showDetailsModal}
        setShowModal={setshowDetailsModal}
        design={design}
      />
      {/* )} */}
      {/* {showEditModal && ( */}
      <EditAnimationModal
        showModal={showEditModal}
        setShowModal={setshowEditModal}
        design={design}
      />
      {/* )} */}
    </div>
  );
}
