import React, { useEffect, useState } from "react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";

export default function ViewModal({ showModal, setShowModal, design }) {
  return (
    <div>
      <TEModal
        show={showModal}
        setShow={setShowModal}
        className="flex items-center"
      >
        <TEModalDialog
          className="min-[576px]:!max-w-[1100px] min-[576px]:!w-[80vw] h-screen max-h-[calc(100vh_-_150px)] md:!h-auto w-full"
          centered
        >
          <TEModalContent className="overflow-scroll h-full no-scrollbar">
            <TEModalHeader className="border-white !px-4 !pt-2 !pb-0">
              <div></div>
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            <TEModalBody className="!pt-0 !mb-4 !mx-2">
              <div className="block md:flex">
                <div className="h-auto md:h-96 flex-1">
                  <div class="max-w-2xl mx-auto h-full">
                    <div class="flex items-center justify-center w-full h-full">
                      <label
                        class={`flex flex-col items-center justify-center w-full h-full rounded-lg cursor-pointe bg-white duration-100`}
                      >
                        <img
                          src={design?.mediaUrl}
                          className="w-auto h-auto max-h-full max-w-full"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="h-fit flex-1 mt-[30px] md:mt-0">
                  <div className="px-[15px] lg:px-[30px] py-1 h-fit">
                    <input
                      readOnly
                      type="text"
                      value={design?.title}
                      className="font-bold text-md px-3 py-2 w-full border-0 outline-0 cursor-default"
                    />
                    <textarea
                      readOnly
                      value={design?.description}
                      className="border-0 w-full px-3 py-2 mt-4 rounded h-fit outline-0 cursor-default resize-none"
                    />
                  </div>
                </div>
              </div>
            </TEModalBody>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>
    </div>
  );
}
