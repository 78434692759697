import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useState } from "react";
import { auth, db } from "../firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [loggedIn, setloggedIn] = useState(false);

  function checkAuth() {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // setloggedIn(true);
          resolve(true);
          // if (!localStorage.getItem("user")) {
          //   resolve(false);
          // } else {
          //   resolve(true);
          // }
        } else {
          // setloggedIn(false);
          // localStorage.removeItem("user");
          resolve(false);
        }
      });
    });
  }

  function logout() {
    signOut(auth)
      .then((res) => {
        setloggedIn(false);
        // localStorage.removeItem("user");
      })
      .catch((err) => {});
  }
  async function login({ email, password }) {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // const adminQuery = query(
      //   collection(db, "admin"),
      //   where("id", "==", user.user.uid)
      // );
      // const admin = await getDocs(adminQuery);
      // const adminData = admin.docs[0].data();
      // const data = {
      //   picture: adminData.picture,
      // };
      // localStorage.setItem("user", JSON.stringify(data));
    } catch (err) {
      throw new Error("Invalid Credentials");
    }
  }
  function getuser() {
    // return JSON.parse(localStorage.getItem("user"));
  }

  const value = {
    checkAuth,
    logout,
    login,
    getuser,
    loggedIn,
    setloggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
