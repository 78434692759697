import React, { useContext, useState } from "react";
import ViewModal from "../Modals/ViewDesignModal";
import EditModal from "../Modals/EditDesignModal";
import ReplaceIcon from "../../assets/replace-icon.svg";
import { AuthContext } from "../../contexts/admin";

export default function Design({ key, design }) {
  const [showDetailsModal, setshowDetailsModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [imgLoaded, setimgLoaded] = useState(false);
  const [showIcon, setshowIcon] = useState(false);

  const { loggedIn } = useContext(AuthContext);

  const handleImgClick = () => {
    loggedIn ? setshowEditModal(true) : setshowDetailsModal(true);
  };

  return (
    <div key={key}>
      <div
        className="relative h-[200px] w-[200px]"
        onMouseEnter={() => setshowIcon(true)}
        onMouseLeave={() => setshowIcon(false)}
      >
        {showIcon && loggedIn && (
          <div className="absolute right-1 bottom-2 z-50">
            <img src={ReplaceIcon} />
          </div>
        )}
        <img
          onLoad={() => setimgLoaded(true)}
          src={design.mediaUrl}
          className={`rounded-md object-cover cursor-pointer hover:grayscale duration-200  h-full w-full ${
            imgLoaded ? "bg-white" : "bg-custom-gray-3"
          }`}
          onClick={handleImgClick}
        />
      </div>
      {/* {showDetailsModal && ( */}
      <ViewModal
        showModal={showDetailsModal}
        setShowModal={setshowDetailsModal}
        design={design}
      />
      {/* )} */}
      {/* {showEditModal && ( */}
      <EditModal
        showModal={showEditModal}
        setShowModal={setshowEditModal}
        design={design}
      />
      {/* )} */}
    </div>
  );
}
