import React, { useEffect, useState } from "react";
import Video from "../../assets/video.mp4";
import Animation from "./Animation";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";

export default function Animations({ animations }) {
  return (
    <>
      <div
        // className="w-11/12 min-[1249px]:!w-4/5 grid grid-cols-1 min-[824px]:!grid-cols-2 min-[1400px]:!grid-cols-3  mx-auto max-w-[1200px] animate-design-initial-render"
        className={`grid grid-cols-1 md:grid-cols-1 min-[1150px]:!grid-cols-2  mx-auto max-w-[900px] animate-design-initial-render w-fit gap-x-20 gap-y-10`}
      >
        {animations.map((design, index) => (
          <>
            <Animation design={{ ...design, key: index }} />
          </>
        ))}
      </div>
    </>
  );
}
